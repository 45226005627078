<template>
    <div class="product">
        <div class="main">
            <div class="main-breadcrumb p-2">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{path:'/procurement'}">煤炭采购</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/zixun' }">资讯中心</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/NoticeList?'+ 'modes=' + 'Procure'}">实时公告</el-breadcrumb-item>
                    <el-breadcrumb-item>阳光采购</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="main-title p-2">
                报价名称：{{ bidname }}
            </div>
            <div class="main-body">
                <el-collapse v-model="activeNames">
                    <el-collapse-item name="10">
                        <template slot="title">
                            <div class="collapse-title p-2">承诺函</div>
                        </template>
                        <el-card>
                            <div class="w-100 h-100 item-8-details">
                                <div class="item-8-title">中煤销售太原有限公司竞价事项说明</div>
                                <div class="item-8-title">({{ bidname }})</div>
                                <div class="item-8-title" style="font-weight: bolder">承诺函</div>
                                <div style="font-weight: bolder">一、综合说明</div>
                                <div style="font-weight: bolder">当前场次</div>
                                <div>1、竞价物资：{{ productInformationTemp.pproductname }}</div>
                                <div>2、竞价单位：</div>
                                <div v-for="(item,index) in LastOffer" :key="index">
                                    <p style="text-indent:1.6em;">{{ item.enterprisename }}</p>
                                </div>
                                <!--                                <div>3、竞价方式：{{this.biddingRules[0].data01}}</div>-->
                                <div>3、竞价期间：{{ detailsStartTime }}至{{ detailsEndTime }}</div>
                                <!--                                <div>5、供货期限：暂定{{transactionAttributes[0].trtime}}，在此期间内，具体发货时间以实际销售安排为准。</div>-->
                                <div>4、竞价商品基本信息:
                                    <div>
                                        <el-table :data="transactionNoticeTemp" class="w-100" border
                                                  :header-cell-style="productInformationStyle">
                                            <el-table-column label="竞价物资" align="center"
                                                             prop="pproductname"></el-table-column>
                                            <el-table-column label="产地" align="center"
                                                             prop="delivery"></el-table-column>
                                            <el-table-column label="交割地" align="center"
                                                             prop="delivery"></el-table-column>
                                            <el-table-column label="提货方式" align="center"
                                                             prop="delivertype"></el-table-column>
                                            <el-table-column label="数量（吨）" align="center"
                                                             prop="quantity"></el-table-column>
                                            <el-table-column label="付款方式" align="center"
                                                             prop="payment"></el-table-column>
                                            <el-table-column label="竞拍基价（元/吨）" align="center"
                                                             prop="price"></el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div>5、交易产品参考质量指标:
                                    <el-table :data="productInformation" class="w-100" border
                                              :header-cell-style="productInformationStyle">
                                        <el-table-column label="更多信息" align="center">
                                            <template>
                                                <span>{{ pdescription }}</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>

                                <div>
                                    <div style="font-weight: bolder">二、竞价内容及说明：</div>
                                    <div>1、实行履约诚意金制度</div>
                                    <div>(1)为维护交易双方的合法权益，参加竞价客户(以下简称“买方”)须缴纳诚意金5万元，需公户付款，不接受个人缴款和现金入账。</div>
                                    <div>(2)买方需在开始竞拍前1小时，一次性缴纳诚意金5万元，诚意金不能冲抵煤款，逾期未足额缴纳诚意金的企业将无法参与竞价交易。诚意金账户信息：</div>
                                    <div>开户行：中国建设银行太原高新技术园区支行</div>
                                    <div> 账 号：1405 0182 6408 0951 8899</div>
                                    <div>开户单位：中煤销售太原有限公司</div>
                                    <div>(3)
                                        竞价交易成功后，买方在两个工作日内签订煤炭买卖合同，全额支付货款。如果买方在规定时间内未签订合同，支付货款，将视为违约，中煤销售太原有限公司将罚没履约诚意金。
                                    </div>
                                    <div>
                                        (4)参与竞价的用户均须严格遵守本交易平台的交易办法、交易流程和交易制度，并严格按照交易规则办理诚意金的提交手续和退还手续。本交易平台对参与竞价用户提交的诚意金不支付任何利息。
                                    </div>
                                    <div>2、竞卖加价：每次报价的最小竞卖加价为1元/吨，基价可以作为挂牌价格。</div>
                                    <div>3、交货地点及方式：在王家岭矿场地买方自提。煤炭运杂费用、运输过程中的安全问题及意外事件由买方自己承担。</div>
                                    <div> 4、结算方式：全额现汇预付货款。</div>
                                    <div> 5、质量和数量的验收标准及方法：质量以王家岭矿化验结果为准，数量以王家岭矿过衡计量数量为结算依据。</div>
                                    <div style="font-weight: bolder">三、注意事项：</div>
                                    <div>
                                        1、报名方式：进入首页，查看到“公开销售”中竞卖信息，点击竞卖信息，进入登录页面，输入账号和密码登录系统（默认密码123456，登录系统后请及时修改密码，妥善保管自己的账号和密码），没有账号用户请进行注册。选择竞卖--等待竞卖--报名。
                                    </div>
                                    <div>2、在线竞价方式：凭注册的用户名及密码登录系统选择竞卖--竞卖中--参与竞价。</div>
                                    <div> 3、竞价结果查询：凭注册用户的微信登录系统选择“我的”--竞卖订单--查看竞价结果。</div>
                                    <div>4、通过本交易平台竞价程序取得成交资格的交易商违约的，将不予退还已经提交的诚意金，竞价标的物将由本交易平台另行处理。请诸位理性出价。</div>
                                    <div> 5、如经本交易平台利用技术手段掌握个别交易商在竞价过程中存在“参与方恶意串通、人为控制交易价格”的行为，则本交易平台有权立即中止竞价或取消竞价。</div>
                                    <div>
                                        6、如因黑客攻击、系统故障、服务器故障等原因，导致竞价中断或无法正常进行的，则该场次的竞价程序无效，且本交易平台有权对该场次竞价全权进行处理，任何交易商均无权干涉。
                                    </div>
                                    <div>7、请妥善保管自己的账号和密码，严禁对外泄露，该帐号下发生的所有竞拍行为平台视为有效，客户自行承担全部责任。</div>
                                    <div style="font-weight: bolder"> 四、其他：</div>
                                    <div>1、该竞价解释权归中煤销售太原有限公司所有。</div>
                                    <div> 2、联系方式
                                        <div>
                                            联系人：杨科长 联系方式：15934048233
                                        </div>
                                        <div style="text-align: right">中煤销售太原有限公司</div>
                                        <div style="text-align: right">{{ detailsCreatTime }}</div>
                                    </div>
                                    <el-button @click="exportWordNotice" type="primary"
                                               style="float: right;margin-bottom: 5px;">导出word
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </el-collapse-item>
                    <el-collapse-item name="1">
                        <template slot="title">
                            <div class="collapse-title p-2">产品信息</div>
                        </template>
                        <div class="item-1">
                            <el-table :data="productInformation" class="w-100" border
                                      :header-cell-style="productInformationStyle">
                                <el-table-column label="产品名称" align="center" prop="pproductname"></el-table-column>
                                <el-table-column label="煤种" align="center" prop="pproducttype">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.pproducttype==0">动力煤</span>
                                        <span v-else-if="scope.row.pproducttype==1">冶金煤</span>
                                        <span v-else>其他</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="报价数量" align="center" prop="quantity"></el-table-column>
                                <el-table-column label="采购单位" align="center" prop="companyname"></el-table-column>
                                <el-table-column label="更多信息" align="center">
                                    <template slot-scope="scope">
                                        <el-link type="primary" @click="checkMore(scope.row.description)">指标说明</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div v-if="moreFlag">
                                <el-card>
                                    <div class="remark" style="white-space:pre-wrap">{{ moreText }}</div>
                                </el-card>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            <div class="collapse-title p-2">交易属性</div>
                        </template>
                        <div class="item-2">
                            <el-table :data="transactionAttributes" class="w-100" border
                                      :header-cell-style="productInformationStyle">
                                <el-table-column label="诚意金" align="center" prop="deposit"></el-table-column>
                                <el-table-column label="诚意金支付截止时间" align="center" prop="deposittime"></el-table-column>
                                <el-table-column label="交割地" align="center" prop="delivery"></el-table-column>
                                <el-table-column label="监督电话" align="center" prop="supervisetel"></el-table-column>
                            </el-table>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            <div class="collapse-title p-2">报价规则</div>
                        </template>
                        <div class="item-3">
                            <el-table :data="biddingRules" class="w-100" border
                                      :header-cell-style="productInformationStyle">
                                <el-table-column label="报价开始时间" align="center" prop="begintime"></el-table-column>
                                <el-table-column label="报价结束时间" align="center" prop="endtime"></el-table-column>
                                <el-table-column label="允许延时   " align="center" prop="data04"
                                                 width="100">
                                    <span v-if="biddingRules[0].data04 == '0'">否</span>
                                    <span v-if="biddingRules[0].data04 != '0'">是</span>
                                </el-table-column>
                                <el-table-column label="延时规则" align="center" prop="data05">
                                    <span v-if="biddingRules[0].data05 == '0'">--</span>
                                    <span v-if="biddingRules[0].data05 != '0'">可以延时拍 {{
                      biddingRules[0].data05
                    }}次,每次延时{{ biddingRules[0].data06 }}分钟</span>
                                </el-table-column>
                                <el-table-column label="延时时长" align="center" prop="data06"
                                                 width="120">
                                    <span v-if="biddingRules[0].data06 == '0'">--</span>
                                    <span v-if="biddingRules[0].data06 != '0'">每次延时{{ biddingRules[0].data06 }} 分钟</span>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="4">
                        <template slot="title">
                            <div class="collapse-title p-2">报价区</div>
                        </template>
                        <div class="item-4">
                            <div class="item-4-state">
                                <div class="w-50 state-name-1 dis-ib" v-if="activeName==4">
                                    <span>距离报价开始时间</span>
                                </div>
                                <div class="w-50 state-name-2 dis-ib" v-else-if="activeName==3">
                                    <span>距离报价结束时间</span>
                                </div>
                                <div class="w-50 state-name-3 dis-ib" v-else
                                     style="border: 1px solid #e5e4e4;color: #d72a2a;font-size: 36px;text-align: center;line-height: 93px;height: 90px;">
                                    <span>报价已结束</span>
                                </div>
                                <div class="w-50  countdown dis-ib">
                                    {{ biddingCountdown }}
                                </div>
                            </div>
                            <el-table :data="transactionAttributes" class="w-100" border
                                      :header-cell-style="productInformationStyle">
                                <el-table-column label="供货最小数量" align="center" prop="minquantity"></el-table-column>
                                <el-table-column label="最小降价单位" align="center" prop="minmarkdown"></el-table-column>
                                <el-table-column label="基准拦标价" align="center" prop="price"></el-table-column>

                                <el-table-column
                                        v-if="transactionAttributes[0].lapsemaxcount != '' && transactionAttributes[0].lapsemaxcount > 0"
                                        label="延时拍 已延" align="center" prop="lapsecount"></el-table-column>
                                <el-table-column
                                        v-if="transactionAttributes[0].lapsemaxcount != '' && transactionAttributes[0].lapsemaxcount > 0"
                                        label="延时拍 剩余" align="center" prop="residual"></el-table-column>
                            </el-table>
                            <div class="item-4-prompt p-2" v-if="userLoginType" align="center">

                                <!--询价信息-->
                                <div v-if="activeName==4">
                                    当前报名状态：
                                    <span v-if="signUpState=='0'">您已报名，等待平台审核！
                                      <el-button type="text" @click="CheckSignUpstates()">
                                        <svg-icon name="fash"></svg-icon>
                                      </el-button>
                                    </span>
                                    <span v-else-if="signUpState=='1'">您已经报名并审核通过，请等待开始竞拍。</span>
                                    <span v-else>未报名，
                                    若想参与本次报价请<el-button type="primary" @click="signUp">报名</el-button>
                                    </span>
                                </div>
                                <!--可报价信息-->
                                <div v-else-if="activeName==3">
                                    <div v-if="signUpState=='1'">
                                        <!--报名成功进行报价-->
                                        <el-form :inline="true" :model="offer" v-if="offerFlag">
                                            <el-form-item>
                                                <span class="pr-5">我当前的报价：{{ myOffer }}</span>
                                            </el-form-item>
                                            <el-form-item>
                                                <span class="pr-5">我当前的数量：{{ myUnmber }}</span>
                                            </el-form-item>
                                            <el-form-item label="价格" class="pl-5">
                                                <el-input-number v-model="offer.biddingPrice" :step="minUpPrice"
                                                                 :max="minPrice"
                                                                 @change="changePrice"
                                                                 placeholder="报价价格"></el-input-number>
                                            </el-form-item>
                                            <el-form-item label="数量">
                                                <el-input-number v-model="offer.biddingNumber" :step="minUpNumber"
                                                                 @change="changeUnmber"
                                                                 :min="minNumber"
                                                                 step-strictly
                                                                 placeholder="报价数量"></el-input-number>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-button type="primary" @click="setOffer">报价</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                    <div v-else>很遗憾！本次报价您并未报名成功,请参与其他竞拍。</div>
                                </div>
                                <!--询价结果-->
                                <div v-else>
                                    <div>报价结果</div>
                                    <el-table :data="LastOffer" class="w-100" border
                                              :header-cell-style="productInformationStyle">
                                        <el-table-column label="报价结果" align="center">
                                            <template slot-scope="scope">
                                                <el-tag
                                                        effect="dark"
                                                        :type="scope.row.bid === '1' ? 'danger' : 'info'"
                                                        disable-transitions>
                                                    <span v-if="scope.row.bid === '1'">已中标</span>
                                                    <span v-else>未中标</span>
                                                </el-tag>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="企业名称" align="center">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.bid === '1'">{{ scope.row.enterprisename }}</span>
                                                <span v-else-if="scope.row.enterprisename==enterpriseName">{{ scope.row.enterprisename }}</span>
                                                <span v-else>************公司</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="报价价格（元/吨）" align="center"
                                                         prop="outbidprice"></el-table-column>
                                        <el-table-column label="报价数量（吨）" prop="bidquantity"
                                                         align="center"></el-table-column>
                                        <el-table-column label="中标数量" prop="bidquantity"
                                                         align="center"></el-table-column>
                                        <el-table-column label="报价时间" align="center"
                                                         prop="outbidtime"></el-table-column>
                                        <el-table-column label="报价次数" align="center"
                                                         prop="count"></el-table-column>
                                    </el-table>
                                </div>
                                <el-table :data="allOffer" class="w-100" border v-if="activeName==4"
                                          :header-cell-style="productInformationStyle">
                                    <el-table-column label="报价公司" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.enterprisename==enterpriseName">{{ scope.row.enterprisename }}</span>
                                            <span v-else>************公司</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="报价价格" align="center"
                                                     prop="outbidprice"></el-table-column>
                                    <el-table-column label="报价数量" align="center"
                                                     prop="outbidquantity"></el-table-column>
                                    <el-table-column label="报价时间" align="center"
                                                     prop="outbidtime"></el-table-column>
                                    <el-table-column label="报价次数" align="center"
                                                     prop="count"></el-table-column>
                                </el-table>
                            </div>
                            <div class="item-4-prompt p-2" style="display: flex"
                                 v-else-if="!userLoginType && activeName=='2'">
                                <div>
                                    <span>如果您想查看本次报价结果,请先</span>
                                </div>
                                <el-button type="primary" @click="gotoLogin">登录</el-button>
                                <span>或者</span>
                                <el-button type="primary" @click="gotoRegist">注册</el-button>
                            </div>
                            <div class="item-4-prompt p-2" style="display: flex" v-else>
                                <div>
                                    <span>如果您想参与本次报价,请先</span>
                                </div>
                                <el-button type="primary" @click="gotoLogin">登录</el-button>
                                <span>或者</span>
                                <el-button type="primary" @click="gotoRegist">注册</el-button>
                            </div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="9" v-if="dataForm.outbidstate!='3'
                                                    &&dataForm.outbidstate!='4'
                                                    &&dataForm.outbidstate!='2'
                                                    &&loginCode.indexOf(user_id) > -1">
                        <template slot="title">
                            <div class="collapse-title p-2">中标通知</div>
                        </template>
                        <el-card>
                            <div class="w-100 h-100 item-8-details">
                                <div class="item-8-title">“中煤商务”{{ detailsCreatTime }}竞价中标通知</div>
                                <div class="item-8-title">({{ bidname }})</div>
                                <div>1、竞价物资：{{ productInformationTemp.pproductname }}</div>
                                <div>2、竞价单位：</div>
                                <div v-for="(item,index) in LastOffer" :key="index">
                                    <p style="text-indent:1.6em;">{{ item.enterprisename }}</p>
                                </div>
                                <div>3、竞拍数量：{{ productInformationTemp.quantity }}吨</div>
                                <div>4、竞拍基价：{{ transactionAttributesTemp.price }}元/吨</div>
                                <br/>
                                <div>第二场竞价结果</div>
                                <div>竞价时间：{{ detailsStartTime }}至{{ detailsEndTime }}</div>
                                <div>中标价格：
                                    <div v-for="(item,index) in LastOffer" :key="index" v-if="item.bid=='1'">
                                        {{ index + 1 }}、
                                        {{ item.enterprisename }}{{ item.outbidprice }}元/吨。
                                    </div>
                                </div>
                                <div>中标数量:</div>
                                <div v-for="(item,index) in LastOffer" :key="index" v-if="item.bid=='1'">
                                    {{ index + 1 }}、{{ item.enterprisename }}
                                    <span v-if="item.bidquantity === null">{{ 0 }}</span>
                                    <span v-else>{{ item.bidquantity }}</span>吨
                                </div>
                                <div>中标客户：</div>
                                <div v-for="(item,index) in LastOffer" :key="index" v-if="item.bid=='1'">
                                    {{ index + 1 }}、{{ item.enterprisename }}
                                </div>
                                <p style="text-indent:2em;">请中标客户于中标之日起2个工作日内全额支付货款，否则视为违约，
                                    中销太原公司有权罚没履约诚意金。</p>
                                <div style="text-align: right">中煤销售太原有限公司</div>
                                <div style="text-align: right">{{ detailsCreatTime }}</div>
                            </div>
                            <el-button @click="exportWordInform" type="primary"
                                       style="float: right;margin-bottom: 5px;">导出word
                            </el-button>
                        </el-card>
                    </el-collapse-item>
                    <!--                    <el-collapse-item name="8" v-if="activeName!=4&&activeName!=3">-->
                    <!--                        <template slot="title">-->
                    <!--                            <div class="collapse-title p-2">竞价详情</div>-->
                    <!--                        </template>-->
                    <!--                        <el-card>-->
                    <!--                            <div class="w-100 h-100 item-8-details">-->
                    <!--                                <div class="item-8-title">中煤销售太原有限公司冶金煤销售</div>-->
                    <!--                                <div class="item-8-title">({{bidname}})</div>-->
                    <!--                                <div class="item-8-title" style="font-weight: bolder">竞价详情</div>-->
                    <!--                                <div>竞价开始时间：{{detailsStartTime}}</div>-->
                    <!--                                <div>竞价结束时间：{{detailsEndTime}}</div>-->
                    <!--                                <div>货物种类：{{productInformationTemp.pproductname}}</div>-->
                    <!--                                <div>总量：{{productInformationTemp.quantity}}吨</div>-->
                    <!--                                <div>质量和数量的验收标准及方法：质量以王家岭矿化验结果为准，数量以王家岭矿过衡计量数量为结算依据。</div>-->
                    <!--                                <div>价格类型：含税出厂价</div>-->
                    <!--                                <div>底价：{{transactionAttributesTemp.price}}元/吨</div>-->
                    <!--                                <div>指标说明：{{productInformationTemp.description}}</div>-->
                    <!--                                <div>其他说明：{{description}}</div>-->
                    <!--                                <div>报名客户（排名不分先后顺序）：</div>-->
                    <!--                                <div v-for="(item,index) in signUpData" :key="index">-->
                    <!--                                    {{index+1}}、{{item.enterprisename}}-->
                    <!--                                </div>-->
                    <!--                                <div>参与客户（排名不分先后顺序）：</div>-->
                    <!--                                <div v-for="(item,index) in LastOffer" :key="index">-->
                    <!--                                    {{index+1}}、{{item.enterprisename}}-->
                    <!--                                </div>-->
                    <!--                                <div>竞价过程：</div>-->
                    <!--                                <div v-for="(item,index) in offerProcess">-->
                    <!--                                    {{index+1}}、-->
                    <!--                                    {{item.outbidtime}}-->
                    <!--                                    {{item.enterprisename}}报价-->
                    <!--                                    {{item.outbidprice}}元/吨-->
                    <!--                                </div>-->
                    <!--                                <div>中标结果：</div>-->
                    <!--                                <div v-for="(item,index) in LastOffer" :key="index" v-if="item.bid=='1'">-->
                    <!--                                    {{index+1}}、-->
                    <!--                                    {{item.enterprisename}}中标价{{item.outbidprice}}元/吨。-->
                    <!--                                </div>-->
                    <!--                                <div style="text-align: right">中煤销售太原有限公司</div>-->
                    <!--                                <div style="text-align: right">{{detailsCreatTime}}</div>-->
                    <!--                            </div>-->
                    <!--                            <el-button @click="exportWord" type="primary" style="float: right;margin-bottom: 5px;">导出word</el-button>-->
                    <!--                        </el-card>-->
                    <!--                    </el-collapse-item>-->
                    <el-collapse-item name="5">
                        <template slot="title">
                            <div class="collapse-title p-2">采购单位联系信息</div>
                        </template>
                        <div class="item-6">
                            <el-table :data="seller" class="w-100" border :header-cell-style="productInformationStyle">
                                <el-table-column label="采购单位" align="center" prop="companyname"></el-table-column>
                                <el-table-column label="联系电话" align="center" prop="tel"></el-table-column>
                            </el-table>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="6">
                        <template slot="title">
                            <div class="collapse-title p-2">备注信息</div>
                        </template>
                        <div class="item-7">
                            <el-card>
                                <div class="remark" style="white-space:pre-wrap">{{ description }}</div>
                            </el-card>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {mapGetters} from "vuex";
    import JSZipUtils from "jszip-utils"
    import docxtemplater from "docxtemplater"
    import {saveAs} from 'file-saver'
    import JSZip from 'jszip'

    export default {
        data() {
            return {
                activeName: '',
                //折叠框默认展开项
                activeNames: ['1', '2', '3', '4', '5', '6'],
                //查询当前商品信息所需信息
                selectData: {
                    "ptargetid": this.$route.query.ptargetid,
                },
                //报价名称
                bidname: '',
                loginCode: [],
                bidNumber: '',
                //table表头样式
                productInformationStyle: {
                    'font-size': '11px',
                    'background': 'whitesmoke',
                    'text-align': 'center',
                    'color': '#333333',
                    'font-family': 'Microsoft YaHei',
                    'font-weight': 'normal',
                },
                //产品信息table数据
                productInformation: [],
                user_id: '',
                //产品信息数据模板
                productInformationTemp: {
                    'pproductname': '',
                    'pproducttype': '',
                    'quantity': '',
                    'companyname': '',
                    'description': '',
                },
                //指标详情开关
                moreFlag: false,
                //指标详情内容
                moreText: '',
                //交易属性table数据
                transactionAttributes: [],
                transactionNotice: {
                    pproductname: '',
                    delivery: '',
                    delivertype: '',
                    minquantity: '',
                    payment: '',
                    price: '',
                },
                transactionNoticeTemp: [],
                //交易属性数据模板
                transactionAttributesTemp: {
                    'deposit': '',
                    'deposittime': '',
                    'delivery': '',
                    'minquantity': '',
                    'minmarkdown': '',
                    'price': '',
                    //监督电话
                    'supervisetel': '',
                },
                dataForm: {
                    targetid: "",
                    splitstate: "",
                    outbidstate: ""
                },
                //报价规则table数据
                //竞价规则table数据
                biddingRules: [
                    {
                        data01: '公开竞价', begintime: '', endtime: '', data04: '否',
                        data05: '--',
                        data06: '--',
                        // data07: '按自报价',
                    }
                ],
                //报价规则模板
                biddingRulesTemp: {
                    'bidtype': '',
                    'begintime': '',
                    'endtime': '',
                },

                quantity: '',
                //采购单位table信息
                seller: [],
                //采购单位信息模板
                sellerTemp: {
                    'companyname': '',
                    'tel': '',
                },
                //备注说明
                description: '',
                //登录状态
                userLoginType: false,
                //报名状态
                signUpState: '',
                //报价信息
                offer: {
                    biddingPrice: '',
                    biddingNumber: '',
                },
                //当前我的报价
                myOffer: '',
                //当前我的数量
                myUnmber: '',
                //产品详情
                pdescription: "",
                //所有报价table信息
                allOffer: [],
                //竞价倒计时
                biddingCountdown: '',
                biddingEndTime: '',
                outbidstate: '',
                enterpriseName: '',
                //最小价格
                minPrice: '',
                //最小数量
                minNumber: '',
                //最小加价
                minUpPrice: '',
                //最小加数量
                minUpNumber: '',
                allOfferList: {},
                rest: '',
                outState: {},
                LastOffer: [],
                LastUser: [],
                offerFlag: true,
                detailsStartTime: '',
                detailsEndTime: '',
                detailsCreatTime: '',
                offerProcess: '',
                signUpData: '',
                wordForm: '',
                table1: '',
                table2: '',
                table3: '',
                table4: '',
                // lockReconnect: false, //是否真正建立连接
                // timeout: 28*1000, //30秒一次心跳
                // timeoutObj: null, //心跳心跳倒计时
                // serverTimeoutObj: null, //心跳倒计时
                // timeoutnum: null, //断开 重连倒计时
                pageClose: false, // 页面是否关闭
                readyState: 0,
                getsystemTime: '',//获取服务器时间
                remaining: '',//竞拍剩余时间(秒)
                count: '',
                start: '',
                interval: 1000,
            }
        },
        created() {
            //页面刚进入时开启长连接
            // this.getState()
            //this.initWebSocket();
        },
        destroyed: function () {
            //页面销毁时关闭长连接
            this.websocketclose();
        },
        watch: {
            "$route.path": function (newVal, oldVal) {
                if (newVal != '/product') {
                    clearTimeout(this.allOfferList);
                    clearTimeout(this.outState)
                }
                if (newVal != '/auction' || newVal != '/product') {
                    if (this.$root.websock && this.$root.websock.readyState === 1) {
                        this.$root.websock.close();
                        clearTimeout(this.$root.timeoutObj);
                        clearTimeout(this.$root.serverTimeoutObj);
                    }
                }
                // else if (newVal == '/product') {
                //   this.getState()
                // }
            },
            rest(newVal, oldVal) {
                if (newVal <= 0) {
                    this.getDataList();
                }
            },
        },
        activated() {
            this.enterpriseName = this.getUser().enterprisename;
            this.selectData.ptargetid = this.$route.query.ptargetid;
            //登录人的code
            this.user_id = this.$store.getters.getUser.enterprisecode;
            this.getDataList();
            this.getUserLoginType();
            this.getUserSignUpState();
            this.initWebSocket();
        },
        methods: {
            //导入vuex中的方法
            ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
            ...mapGetters(["getUser", "getNum"]),
            // 获取服务器得时间
            getNewTime() {
                this.$post('system/getTime').then((data) => {
                    if (data && data.code === 0) {
                        this.getsystemTime = data.systemTime
                    } else {
                        this.$message({
                            message: data.msg,
                            offset: 130,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            //改变价格
            changePrice(val) {
                if ((Number(val) - Number(this.myOffer)) % Number(this.minUpPrice) != 0) {
                    this.$message({
                        message: '您修改的价格不是最小降价单位的倍数',
                        offset: 130,
                        type: 'error',
                        showClose: 'true'
                    })
                }
                this.offer.biddingPrice = val;
                // this.myOffer = val;
            },
            //改变数量
            changeUnmber(val) {
                if ((Number(val) - Number(this.myUnmber)) % Number(this.minUpNumber) != 0) {
                    this.$message({
                        message: '您修改的数量不是最小供货价格数量的倍数',
                        offset: 130,
                        type: 'error',
                        showClose: 'true'
                    })
                }
                this.offer.biddingNumber = val;
            },
            //获取商品信息
            getDataList() {
                this.$post('tptargetinfo/detail', this.selectData, true).then((data) => {
                    if (data && data.code === 0) {
                        //数据初始化
                        this.productInformation = [];
                        this.transactionAttributes = [];
                        this.transactionNoticeTemp = [];
                        this.biddingRules = [];
                        this.seller = [];
                        //报价名称赋值
                        this.bidname = data.targetdetail.bidname;
                        //产品信息赋值
                        this.productInformationTemp.pproductname = data.productdetail.pproductname;
                        this.productInformationTemp.pproducttype = data.productdetail.pproducttype;
                        this.productInformationTemp.quantity = data.targetdetail.quantity;
                        this.productInformationTemp.companyname = data.targetdetail.companyname;
                        this.productInformationTemp.description = data.productdetail.description;
                        this.productInformation.push(this.productInformationTemp);
                        //交易属性赋值
                        this.transactionAttributesTemp.deposit = data.targetdetail.deposit;
                        this.transactionAttributesTemp.deposittime = data.targetdetail.deposittime;
                        this.transactionAttributesTemp.supervisetel = data.targetdetail.supervisetel;
                        this.transactionAttributesTemp.delivery = data.targetdetail.delivery;
                        this.transactionAttributesTemp.minquantity = data.targetdetail.minquantity;
                        this.transactionAttributesTemp.minmarkdown = data.targetdetail.minmarkdown;
                        this.transactionAttributesTemp.price = data.targetdetail.price;
                        //延时拍已延次数
                        this.transactionAttributesTemp.lapsecount = data.targetdetail.lapsecount;
                        //延时拍总次数
                        this.transactionAttributesTemp.lapsemaxcount = data.targetdetail.lapsemaxcount;
                        //延时拍剩余次数
                        this.transactionAttributesTemp.residual = parseFloat(data.targetdetail.lapsemaxcount) - parseFloat(data.targetdetail.lapsecount);
                        this.transactionAttributes.push(this.transactionAttributesTemp);
                        //报价规则赋值
                        this.biddingRulesTemp.bidtype = data.targetdetail.bidtype;
                        this.biddingRulesTemp.begintime = data.targetdetail.begintime;
                        this.biddingRulesTemp.endtime = data.targetdetail.endtime;
                        this.biddingRules.push(this.biddingRulesTemp)
                        this.biddingRules[0].data04 = data.targetdetail.lapsemaxcount;
                        this.biddingRules[0].data05 = data.targetdetail.lapsemaxcount;
                        this.biddingRules[0].data06 = data.targetdetail.lapsetime;
                        //采购单位赋值
                        this.sellerTemp.companyname = data.targetdetail.companyname;
                        this.sellerTemp.tel = data.targetdetail.tel;
                        this.seller.push(this.sellerTemp);
                        //公告竞价商品基本信息
                        this.transactionNotice.pproductname = data.productdetail.pproductname;
                        this.transactionNotice.delivery = data.targetdetail.delivery;
                        this.transactionNotice.delivertype = data.targetdetail.delivertype;
                        this.transactionNotice.minquantity = data.targetdetail.minquantity;
                        this.transactionNotice.payment = data.targetdetail.payment;
                        this.transactionNotice.quantity = data.targetdetail.quantity;
                        this.transactionNotice.price = data.targetdetail.price;
                        this.transactionNoticeTemp.push(this.transactionNotice);
                        this.transactionAttributes[0].trtime = "从" + data.targetdetail.trbegintime + "至" + data.targetdetail.trendtime;
                        //最小价格
                        this.minPrice = Number(data.targetdetail.price);

                        if (this.myOffer != '') {
                            this.offer.biddingPrice = this.myOffer;//默认价格
                        } else {
                            this.offer.biddingPrice = this.minPrice;//默认价格
                        }

                        //最小数量
                        this.minNumber = Number(data.targetdetail.minquantity);
                        //最小加价
                        this.minUpPrice = Number(data.targetdetail.minmarkdown);
                        //最小加数量
                        this.minUpNumber = Number(data.targetdetail.minquantity);

                        this.offer.biddingNumber = this.minUpNumber;//默认数量

                        this.dataForm.outbidstate = data.targetdetail.outbidstate;
                        //默认值设置
                        // this.offer.biddingPrice = data.targetdetail.price

                        // this.outbidstate = data.targetdetail.outbidstate

                        this.activeName = data.targetdetail.outbidstate;
                        this.pdescription = data.productdetail.description;

                        //备注说明赋值
                        this.description = data.targetdetail.description;

                        this.getDetailsTime();
                        this.getOfferProcess();
                        this.getSignUp();
                        if (data.targetdetail.outbidstate == '4') {
                            this.biddingEndTime = data.targetdetail.begintime;
                            this.getRemaining(this.biddingEndTime);
                            this.startCountdown();
                            if (this.getUser()) {
                                this.CheckSignUpstates();
                            }
                        } else if (data.targetdetail.outbidstate == '3') {
                            this.biddingEndTime = data.targetdetail.endtime;
                            this.getRemaining(this.biddingEndTime);
                            this.startCountdown();
                            this.getMyPrice()
                        } else {
                            this.biddingEndTime = '';
                            this.getLastOffer();
                        }
                        this.getAllOffer();
                        this.getMyOffer();

                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            offset: 130,
                            showClose: 'true'
                        });
                    }
                })
            },
            //客户状态
            CheckSignUpstates() {
                const param = {
                    targetid: this.$route.query.ptargetid,
                    membercode: this.getUser().membercode,
                    enterprisecode: this.getUser().enterprisecode,
                    tel: this.getUser().tel
                };
                this.$post('ppcoutbidenrollinfo/pccheckstates', param).then((data) => {
                    if (data && data.code === 0) {
                        // 0： 报名未审核，1：报名已审核,99:未报名
                        if (data.auditstates === "0") {
                            this.signUpState = '0';
                        } else if (data.auditstates === "1") {
                            this.signUpState = '1';
                        } else {
                            this.signUpState = '';
                        }
                    } else {
                        this.$message({
                            message: data.msg,
                            offset: 130,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            //查看指标详情
            checkMore(text) {
                this.moreFlag = !this.moreFlag;
                this.moreText = text;
            },
            //获取当前用户是否登录
            getUserLoginType() {
                if (this.getUser()) {
                    this.userLoginType = true;
                } else {
                    this.userLoginType = false;
                }
            },
            //跳转登录页面
            gotoLogin() {
                this.$router.push({path: "/login"});
            },
            //跳转注册页面
            gotoRegist() {
                this.$router.push({path: "/register"});
            },
            //获取当前用户报名情况
            getUserSignUpState() {
                if (this.activeName != '2') {
                    const param = {
                        targetid: this.$route.query.ptargetid,
                        membercode: this.getUser().membercode,
                        enterprisecode: this.getUser().enterprisecode,
                        tel: this.getUser().tel
                    }
                    this.$post('ppcoutbidenrollinfo/pccheckstates', param, true).then((data) => {
                        if (data && data.code === 0) {
                            this.signUpState = data.auditstates;

                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                offset: 130,
                                showClose: 'true'
                            })
                        }
                    })
                }
            },
            //报名
            signUp() {
                const param = {
                    createuser: this.getUser().memberid,
                    targetid: this.$route.query.ptargetid,
                    membercode: this.getUser().membercode,
                    enterprisecode: this.getUser().enterprisecode,
                    contacts: this.getUser().membername,
                    tel: this.getUser().tel
                };
                this.$post('ppcoutbidenrollinfo/insertOutbidenrollinfo', param, true).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: "报名成功！",
                            offset: 130,
                            type: 'success',
                        })
                        //重新查询当前报名状态
                        this.getUserSignUpState();
                    } else {
                        this.$message({
                            // message: data.msg,
                            message: '报名失败',
                            offset: 130,
                            type: 'error',
                        })
                    }
                })
            },
            //获取当前用户的报价
            getMyOffer() {
                if (this.activeName == '3') {
                    const param = {
                        targetid: this.$route.query.ptargetid,
                        membercode: this.getUser().membercode,
                        enterprisecode: this.getUser().enterprisecode,
                    };
                    this.$post('ppcoutbidprocessinfo/getNewOutbidprice', param, true).then((data) => {
                        if (data && data.code === 0) {
                            this.myOffer = data.data.outbidprice;
                            this.offer.biddingPrice = data.data.outbidprice;
                            this.myUnmber = data.data.outbidquantity;
                            this.offer.biddingNumber = data.data.outbidquantity;
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                offset: 130,
                                showClose: 'true'
                            })
                        }
                    })
                }
            },
            //获取所有报价信息
            getAllOffer() {
                if (this.activeName != '4') {
                    const param = {
                        targetid: this.$route.query.ptargetid,
                    };
                    this.$post('ppcoutbidprocessinfo/getNewOutbidpriceByTargetid', param, true).then((data) => {
                        if (data && data.code === 0) {
                            this.allOffer = data.data;
                            this.getBidCount();
                            this.newInfoTime();
                            this.getRemaining(this.biddingEndTime);
                            this.startCountdown();
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                offset: 130,
                                showClose: 'true'
                            })
                        }
                    })
                }
            },
            newInfoTime() {
                this.$post('tptargetinfo/detail', {"ptargetid": this.$route.query.ptargetid,}, true).then((data) => {
                    if (data && data.code === 0) {
                        this.biddingEndTime = data.targetdetail.endtime;
                    }
                })
            },
            //进行报价
            setOffer() {
                // if (this.allOffer[0] != undefined && this.offer.biddingPrice > this.allOffer[0].outbidprice) {
                //   this.$message({
                //     title: '错误',
                //     offset: 130,
                //     message: '当前报价不能高于或等于原报价',
                //     type: 'error',
                //     showClose: 'true'
                //   })
                //   return;
                // }else if (this.allOffer[0] != undefined && this.offer.biddingPrice === this.allOffer[0].outbidprice) {
                //   this.$message({
                //     title: '错误',
                //     offset: 130,
                //     message: '请提高报价',
                //     type: 'error',
                //     showClose: 'true'
                //   })
                //   return;
                // }
                // else if(this.allOffer[0] != undefined && this.offer.biddingPrice == this.allOffer[0].outbidprice && this.allOffer[0].outbidquantity >= this.offer.biddingNumber) {
                //     this.$message({
                //       title: '错误',
                //       offset: 130,
                //       message: '竞拍价格相同，请提高出价数量。',
                //       type: 'error',
                //     })
                //     return;
                // }
                if (this.myOffer != '' && this.myOffer === this.offer.biddingPrice && Number(this.offer.biddingNumber) <= Number(this.myUnmber)) {
                    this.$message({
                        title: '错误',
                        offset: 130,
                        message: '本次报价数量应大于上次报价数量。',
                        type: 'error',
                    })
                    return;
                }
                if (this.myOffer != '' && this.myOffer < this.offer.biddingPrice) {
                    this.$message({
                        title: '错误',
                        offset: 130,
                        message: '本次报价不能大于上次报价。',
                        type: 'error',
                    })
                    return;
                } else if (this.myOffer != '' && this.myOffer <= this.offer.biddingPrice && Number(this.offer.biddingNumber) === Number(this.myUnmber)) {
                    this.$message({
                        title: '错误',
                        offset: 130,
                        message: '本次报价价格应小于上次报价价格。',
                        type: 'error',
                    })
                    return;
                } else {
                    const param = {
                        createuser: this.getUser().memberid,
                        targetid: this.$route.query.ptargetid,
                        membercode: this.getUser().membercode,
                        enterprisecode: this.getUser().enterprisecode,
                        outbidprice: this.offer.biddingPrice,
                        outbidquantity: this.offer.biddingNumber
                    };
                    this.$post('ppcoutbidprocessinfo/insertOutbidprocessinfo', param, true).then((data) => {
                        if (data && data.code === 0) {
                            //报价成功后重新查询报价列表以及当前用户报价信息
                            this.getMyOffer();
                            this.getDataList();
                            this.$message({
                                showClose: true,
                                offset: 130,
                                type: 'success',
                                message: '报价成功',
                            });
                        } else {
                            this.$message({
                                offset: 130,
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                }
            },

            getMyPrice() {
                const param = {
                    targetid: this.$route.query.ptargetid,
                    membercode: this.getUser().membercode,
                    enterprisecode: this.getUser().enterprisecode,
                };
                this.$post('pcoutbidprocessinfo/getNewOutbidprice', param).then((data) => {
                    if (data && data.code === 0) {
                        if (data.data === null) {
                            this.currentPrice = '';
                            this.biddingPrice = '';

                        } else {
                            this.currentPrice = data.data.outbidprice;
                            // this.biddingPrice = data.data.outbidprice;
                        }
                    } else {
                        this.$message({
                            message: data.msg,
                            offset: 130,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },


            //查询报价结束后的结果
            getLastOffer() {
                const param = {
                    ptargetid: this.$route.query.ptargetid,
                };
                this.$post('ppcoutbidresultinfo/selectOutbidresultinfoList', param).then((data) => {
                    if (data && data.code === 0) {
                        this.LastOffer = data.data;
                        this.getBidCount();
                        for (let i = 0; i < data.data.length; i++) {
                            //竞价客户的code
                            if (data.data[i].bid === '1') {
                                this.loginCode.push(data.data[i].enterprisecode);
                            }
                        }
                    } else {
                        this.$message({
                            offset: 130,
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            //获取竞拍剩余时间方法  endTime:竞拍结束时间
            getRemaining(finishTime) {
                this.getNewTime()
                //倒计时只精确到秒
                let nowTime = new Date(this.getsystemTime.replace(/-/g, '/'));
                let endTime = new Date(finishTime);
                //计算竞拍剩余时间(秒)
                let rest = Math.trunc((endTime.getTime() - nowTime.getTime()));
                this.remaining = rest;
                this.start = new Date().getTime();
                this.count = 0;
            },
            //启动倒计时
            startCountdown() {
                //由于线程阻塞延迟问题，做了setTimeout 执行时间的误差修正，保证setTimeout执行时间一致
                let offset, nextTime;
                this.count++;
                offset = new Date().getTime() - (this.start + this.count * this.interval);
                nextTime = this.interval - offset;
                if (nextTime < 0) {
                    nextTime = 0;
                }
                this.biddingCountdown = this.timeChange(this.remaining / 1000);
                this.remaining -= this.interval;
                if (this.remaining > 0) {
                    setTimeout(this.startCountdown, nextTime);
                } else {
                    this.biddingState = true;
                    this.getState();
                }
            },
            //时间转化方法
            timeChange(time) {
                let day = Math.trunc(time / 60 / 60 / 24);
                let hours = Math.trunc((time / 60 / 60) % 24);
                let minutes = Math.trunc((time / 60) % 60);
                let seconds = Math.trunc(time % 60);
                return (day + '天 ' + hours + '时 ' + minutes + '分 ' + seconds + '秒');
            },
            initWebSocket: function () {
                // var socketUrl = process.env.VUE_APP_API.replace("https://", "wss://").replace("http://", "ws://") + "/websocket";
                var socketUrl = "";
                // if (this.$root.websock && this.$root.websock.readyState===1){
                //   this.$root.websock.close()
                //   this.$root.lockReconnect = false
                //   this.websocketreconnect()
                // } else {
                this.$root.websock = new WebSocket(socketUrl);
                this.$root.websock.onopen = this.websocketonopen;
                this.$root.websock.onerror = this.websocketonerror;
                this.$root.websock.onmessage = this.websocketonmessage;
                this.$root.websock.onclose = this.websocketclose;
                // }
            },
            websocketonopen: function (e) {
                console.log("ket连接成功");
                this.readyState = 1
                this.websocketstart(e);
            },
            websocketonerror: function (e) {
                console.log(e, "ket连接发生错误");
                this.websocketreconnect();
            },
            websocketonmessage: function (e) {
                console.log("接受到消息处理");
                // if (e.data === 'close' && (this.$route.path != '/product' || this.$route.path != '/auction')){
                //   this.websocketclose(e);
                // } else {
                this.websocketreset(e);
                if (e.data === this.dataForm.targetid) {
                    // this.getState();
                    this.getAllOffer();
                }
                // }
                // this.getAllOffer();

            },
            websocketclose: function (e) {
                console.log(e, 'ket close')
                // this.$root.websock.close();
                // //清除时间
                // clearTimeout(this.$root.timeoutObj);
                // clearTimeout(this.$root.serverTimeoutObj);
                // this.readyState = 0
                // if (!this.pageClose){
                //   this.websocketreconnect()
                // }
            },
            websocketreconnect() {
                console.log("ket重新连接");
                //重新连接
                let that = this;
                if (that.$root.lockReconnect) {
                    return;
                }

                that.$root.lockReconnect = true;
                //没连接上会一直重连，设置延迟避免请求过多
                that.$root.timeoutnum && clearTimeout(that.$root.timeoutnum);
                that.$root.timeoutnum = setTimeout(function () {
                    //新连接
                    that.initWebSocket();
                    that.$root.lockReconnect = false;
                }, 1000);
            },
            websocketreset(e) {//重置心跳
                let that = this;
                //清除时间
                clearTimeout(that.$root.timeoutObj);
                clearTimeout(that.$root.serverTimeoutObj);
                //重启心跳
                that.websocketstart(e);
            },
            websocketstart(e) {//开启心跳
                let self = this;
                self.$root.timeoutObj && clearTimeout(self.$root.timeoutObj);
                self.$root.serverTimeoutObj && clearTimeout(self.$root.serverTimeoutObj);
                self.$root.timeoutObj = setTimeout(function () {
                    //这里发送一个心跳，后端收到后，返回一个心跳消息，
                    if (self.$root.websock.readyState === 1) {//如果连接正常
                        self.$root.websock.send("heartCheck Product");
                    } else {//否则重连
                        self.websocketreconnect();
                    }
                    self.$root.serverTimeoutObj = setTimeout(function () {
                        //超时关闭
                        self.websocketclose();
                    }, self.$root.timeout);

                }, self.$root.timeout)
            },
            //获取当前竞拍状态
            getState() {
                this.$post('tptargetinfo/detail', {"ptargetid": this.$route.query.ptargetid,}, true).then((data) => {
                    this.activeName = data.targetdetail.outbidstate
                    let stateTime = new Date(data.targetdetail.endtime).getTime();
                    let nowTime = new Date().getTime();
                    if (this.activeName == '0') {
                        this.biddingCountdown = '竞价结果：已拍下'
                        clearTimeout(this.outState)
                        return
                    } else if (this.activeName == '1') {
                        this.biddingCountdown = '竞价结果：已流拍'
                        clearTimeout(this.outState)
                        return
                    } else if (this.activeName == '2') {
                        this.biddingCountdown = '竞价结果：已关闭'
                        clearTimeout(this.outState)
                        return
                    } else if (this.activeName == '3') {
                        if (stateTime - nowTime < 1000) {
                            this.biddingCountdown = '竞拍已结束,请等待竞拍结果'
                            this.offerFlag = false
                        }
                    }
                    if (data.targetdetail.outbidstate == '4') {
                        this.outState = setTimeout(this.getState, 1000)
                    }
                })
            },
            getDetailsTime() {
                let start = this.biddingRulesTemp.begintime;
                let end = this.biddingRulesTemp.endtime;
                this.toNyr(start)
                this.detailsStartTime = this.toNyr(start);
                this.detailsEndTime = this.toNyr(end);
                let newDate = end.split(' ');
                let day = newDate[0].split('/');
                this.detailsCreatTime = day[0] + '年 ' + day[1] + '月 ' + day[2] + '日';
            },
            toNyr(date) {
                let newDate = date.split(' ');
                let day = newDate[0];
                let time = newDate[1];
                day = day.split('/');
                time = time.split(':');
                let newDay = day[0] + '年 ' + day[1] + '月 ' + day[2] + '日 ';
                let newTime = time[0] + '时 ' + time[1] + '分 ' + time[2] + '秒';
                return newDay + newTime;
            },
            getOfferProcess() {
                const param = {
                    targetid: this.$route.query.ptargetid,
                };
                this.$post('ppcoutbidprocessinfo/getOutbidInfo', param).then((data) => {
                    if (data && data.code === 0) {
                        this.offerProcess = data.data;
                    } else {
                        this.$message({
                            offset: 130,
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            getSignUp() {
                const param = {
                    targetid: this.$route.query.ptargetid,
                };
                this.$post('ppcoutbidenrollinfo/getOutbidenrollinfo', param).then((data) => {
                    if (data && data.code === 0) {
                        this.signUpData = data.data;
                    } else {
                        this.$message({
                            offset: 130,
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            //刷新当前页面
            refresh() {
                this.$router.go(0);
            },
            exportWordNotice: function () {
                let _this = this;
                let wordForm = {
                    bidname: _this.bidname,
                    pproductname: _this.productInformationTemp.pproductname,
                    companyname: _this.productInformationTemp.companyname,
                    detailsStartTime: _this.detailsStartTime,
                    detailsEndTime: _this.detailsEndTime,
                    productname: _this.productInformationTemp.pproductname,
                    quantity: _this.productInformation[0].quantity,
                    price: _this.productInformation[0].price,
                    description1: _this.pdescription,
                    description2: _this.description,
                    detailsCreatTime: _this.detailsCreatTime,
                    data01: _this.biddingRules[0].data01,
                    trtime: _this.transactionAttributes[0].trtime,
                }
                let table1 = _this.setIndex(_this.transactionNoticeTemp);
                let table2 = _this.setIndex(_this.productInformation);
                let table3 = _this.setIndex(_this.LastOffer);
                // 读取并获得模板文件的二进制内容
                JSZipUtils.getBinaryContent("testNoice1.docx", function (error, content) {
                    // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
                    // 抛出异常
                    if (error) {
                        throw error;
                    }
                    // 创建一个JSZip实例，内容为模板的内容
                    let zip = new JSZip(content);
                    // 创建并加载docxtemplater实例对象
                    let doc = new docxtemplater().loadZip(zip);
                    // 设置模板变量的值
                    doc.setData({
                        ...wordForm,
                        table1: table1,
                        table2: table2,
                        table3: table3,
                    });

                    try {
                        // 用模板变量的值替换所有模板变量
                        doc.render();
                    } catch (error) {
                        // 抛出异常
                        let e = {
                            message: error.message,
                            name: error.name,
                            stack: error.stack,
                            properties: error.properties
                        };
                        console.log(JSON.stringify({error: e}));
                        throw error;
                    }

                    // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                    let out = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    });
                    // 将目标文件对象保存为目标类型的文件，并命名
                    saveAs(out, "承诺函（" + _this.bidname + "）.docx");
                });
            },


            // 点击导出word
            // exportWord: function () {
            //     let _this = this;
            //     let wordForm = {
            //         bidname: _this.bidname,
            //         detailsStartTime: _this.detailsStartTime,
            //         detailsEndTime: _this.detailsEndTime,
            //         productname: _this.productInformationTemp.pproductname,
            //         quantity: _this.productInformationTemp.quantity,
            //         price: _this.transactionAttributesTemp.price,
            //         description1: _this.productInformationTemp.description,
            //         description2: _this.description,
            //         detailsCreatTime: _this.detailsCreatTime,
            //     }
            //     let table1 = _this.setIndex(_this.signUpData);
            //     let table2 = _this.setIndex(_this.LastOffer);
            //     let table3 = _this.setIndex(_this.offerProcess);
            //     let table4 = [];
            //     for(let i=0;i<_this.LastOffer.length;i++){
            //         if(_this.LastOffer[i].bid=='1'){
            //             table4.push(_this.LastOffer[i])
            //         }
            //     }
            //     // 读取并获得模板文件的二进制内容
            //     JSZipUtils.getBinaryContent("test.docx", function (error, content) {
            //         // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            //         // 抛出异常
            //         if (error) {
            //             throw error;
            //         }
            //
            //         // 创建一个JSZip实例，内容为模板的内容
            //         let zip = new JSZip(content);
            //         // 创建并加载docxtemplater实例对象
            //         let doc = new docxtemplater().loadZip(zip);
            //         // 设置模板变量的值
            //         doc.setData({
            //             ...wordForm,
            //             table1: table1,
            //             table2: table2,
            //             table3: table3,
            //             table4: table4,
            //         });
            //
            //         try {
            //             // 用模板变量的值替换所有模板变量
            //             doc.render();
            //         } catch (error) {
            //             // 抛出异常
            //             let e = {
            //                 message: error.message,
            //                 name: error.name,
            //                 stack: error.stack,
            //                 properties: error.properties
            //             };
            //             console.log(JSON.stringify({error: e}));
            //             throw error;
            //         }
            //
            //         // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            //         let out = doc.getZip().generate({
            //             type: "blob",
            //             mimeType:
            //                 "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            //         });
            //         // 将目标文件对象保存为目标类型的文件，并命名
            //         saveAs(out, "中煤销售太原有限公司冶金煤销售（"+_this.bidname+"）竞价详情.docx");
            //     });
            // },
            // 点击导出中标通知word
            exportWordInform: function () {
                let _this = this;
                let wordForm = {
                    bidname: _this.bidname,
                    detailsStartTime: _this.detailsStartTime,
                    detailsEndTime: _this.detailsEndTime,
                    productname: _this.productInformationTemp.pproductname,
                    quantity: _this.productInformationTemp.quantity,
                    price: _this.transactionAttributesTemp.price,
                    detailsCreatTime: _this.detailsCreatTime,
                }
                //竞价单位
                let table1 = _this.setIndex(_this.LastOffer);
                let table4 = [];
                for (let i = 0; i < _this.LastOffer.length; i++) {
                    if (_this.LastOffer[i].bid == '1') {
                        table4.push(_this.LastOffer[i])
                    }
                }
                // let table2 = _this.setIndex(_this.participate);
                // let table3 = _this.setIndex(_this.participate);
                // // let table4 = [];
                // 读取并获得模板文件的二进制内容
                JSZipUtils.getBinaryContent("testInformBuy.docx", function (error, content) {
                    // input.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
                    // 抛出异常
                    if (error) {
                        throw error;
                    }

                    // 创建一个JSZip实例，内容为模板的内容
                    let zip = new JSZip(content);
                    // 创建并加载docxtemplater实例对象
                    let doc = new docxtemplater().loadZip(zip);
                    let docData = {
                        ...wordForm,
                        table1: table1,
                        // table2: table2,

                        table4: table4,

                    }
                    // 设置模板变量的值
                    doc.setData(docData);

                    try {
                        // 用模板变量的值替换所有模板变量
                        doc.render();
                    } catch (error) {
                        // 抛出异常
                        let e = {
                            message: error.message,
                            name: error.name,
                            stack: error.stack,
                            properties: error.properties
                        };
                        console.log(JSON.stringify({error: e}));
                        throw error;
                    }

                    // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                    let out = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    });
                    // 将目标文件对象保存为目标类型的文件，并命名
                    saveAs(out, "中煤商务" + _this.detailsCreatTime + _this.bidname + "竞价中标通知.docx");
                });
            },
            setIndex(arr) {
                for (let i = 0; i < arr.length; i++) {
                    this.$set(arr[i], 'index', i + 1)
                }
                return arr;
            },
            getBidCount() {
                this.$post('ppcoutbidprocessinfo/getOutbidCount', {"targetid": this.$route.query.ptargetid}, true).then((data) => {
                    for (let i = 0; i < data.data.length; i++) {
                        for (let k = 0; k < this.LastOffer.length; k++) {
                            if (data.data[i].enterprisecode == this.LastOffer[k].enterprisecode) {
                                this.$set(this.LastOffer[k], 'count', data.data[i].count)
                            }
                        }
                        for (let l = 0; l < this.allOffer.length; l++) {
                            if (data.data[i].enterprisecode == this.allOffer[l].enterprisecode) {
                                this.$set(this.allOffer[l], 'count', data.data[i].count)
                            }
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/auction.scss";
</style>
